import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { Slug } from '@/shared/components/Slug'
import { Paragraph2, Headline2, Headline1, Paragraph1 } from '@/shared/components/Typography'
import { Separator } from '@/shared/components/Separator/Separator';
import IconButton from '@/shared/components/Button/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { DEFAULT_TEAM_LOGO } from '@/shared/components/Avatar/Avatar';
import { Img } from '@/shared/components/Img/Img'
import { IGamesSectionData } from '../types'
import { useMemo } from 'react'
import { IGroup } from '@/shared/models/Group'
import { displayGameScore, hasScore } from '@/shared/utils/helpers'
import { getVenueDescription } from '@/shared/utils/addressFunc'
import { useTranslations } from 'contexts/translations'
import { generateTagLabel } from '@/shared/utils/generateTeamName'
import { useIntl } from 'react-intl'
import { sectionMessages } from 'components/Sections/messages'
import moment from 'moment'
import { WebsitePageType } from '@/shared/models/Website'
import { useSystemPagePath } from 'utils/hooks'
import TeamGamesPlaceholder from '../TeamGamesPlaceholder'
import { useGameCardStyles } from '../styles'
import joinElements from '@/shared/utils/joinElements'
import ElementLink from '@/shared/components/ElementLink/ElementLink'
import { FLF_SHOP_LINK, FLF_TICKETS_LINK } from '@/shared/constants'
import ButtonLink from '@/shared/components/Button/ButtonLink'
import useGameLoader from '../hooks/useGameLoader'
import Loader from '@/shared/components/Loader/Loader'
import { useTheme } from '@material-ui/styles'
import { ITheme } from '@/shared/styles/MuiTheme'

const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`
export default function FlfGames(props: IGamesSectionData) {
    const { translations: { messages } } = useTranslations()
    const { games, countries, categories, group, closestGameIndex, initialLoadedGames } = props
    const { currentGame, currentIndex, onNavigate } = useGameLoader({ initialLoadedGames, initialGameIndex: closestGameIndex, games })
    const isCurrentGameLoading = Boolean(!currentGame)
    const intl = useIntl()
    const isFutureGame = useMemo(() => currentGame ? moment(currentGame?.start_date).isAfter() : false, [currentGame])
    const gameLink = `${useSystemPagePath(WebsitePageType.GAME)}/${currentGame?.id}`
    const gameTime = useMemo(getGameTime, [currentGame])
    const isEmpty = games.length === 0;
    const { simpleDateFormatter } = useTranslations()
    const classes = useGameCardStyles({ hasLeagueButton: false })
    const theme = useTheme<ITheme>()
    const textColor = theme.props.sectionMode.text_background
    const hasGameScore = hasScore(currentGame)

    if(isEmpty || !group) {
        return (
            <Box key={1}>
                <TeamGamesPlaceholder title={intl.formatMessage(sectionMessages['games.empty'])}/>
            </Box>
        )
    }

    if(isCurrentGameLoading) {
        return (
            <Box key={2} minHeight={303} display='flex' justifyContent='center' alignItems='center'>
                <Loader/>
            </Box>
        )
    }


    return (
        <Box key={3} display='flex' justifyContent='center' flexDirection='column' minHeight={303}>
            <Hidden mdUp>
                <Box py={4} display='flex' flexDirection='column' alignItems='center' textAlign='right'>
                    {renderGameDetails()}
                </Box>
                <Box className={classes.customDivider} pb={4}>
                    <Separator/>
                </Box>
            </Hidden>
            <Box pb={4} display='flex' alignItems='center'>
                <Hidden smDown>
                    <Box px={1}>
                        {renderBackBtn()}
                    </Box>
                </Hidden>
                <Grid container>
                    <Hidden smDown>
                        <Grid item md={4}>
                            <Box className={classes.customEventDetails} py={4} display='flex' flexDirection='column' alignItems='flex-end' textAlign='center'>
                                {renderGameDetails()}
                                {renderViewEventBtn()}
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item container sm={12} md={8} alignItems='center'>
                        <Box className={classes.teamScoreContainer}>
                            <Grid item xs={3} lg={4} className={(isFutureGame || currentGame.cancelled) && classes.teamPadding}>
                                {renderTeam(currentGame?.team1)}
                            </Grid>
                            <Grid item xs={6} lg={4}>
                                <ElementLink link={gameLink}>
                                    <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center'>
                                        {isFutureGame && !currentGame.cancelled &&(
                                            <Paragraph2>{intl.formatMessage(sectionMessages['games.kick_off'])}</Paragraph2>
                                        )}
                                        {currentGame.cancelled && (
                                            <Paragraph2>{intl.formatMessage(sectionMessages['games.cancelled'])}</Paragraph2>
                                        )}
                                        <Box pl={2} pr={2} p={3} className={currentGame.cancelled && hasGameScore && classes.cancelledText}>
                                            <Headline1>{isFutureGame ? gameTime : displayGameScore(currentGame)}</Headline1>
                                        </Box>
                                    </Box>
                                </ElementLink>
                            </Grid>
                            <Grid item xs={3} lg={4} className={(isFutureGame || currentGame.cancelled) && classes.teamPadding}>
                                {renderTeam(currentGame?.team2)}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Box px={1}>
                        {renderForwardBtn()}
                    </Box>
                </Hidden>
            </Box>
            <Hidden mdUp>
                <Box pb={4}>
                    <Grid container>
                        <Grid item xs={2} container alignItems='center' justifyContent='flex-start'>
                            {renderBackBtn()}
                        </Grid>
                        <Grid item xs={8} container alignItems='center' justifyContent='center'>
                            {renderViewEventBtn()}
                        </Grid>
                        <Grid item xs={2} container alignItems='center' justifyContent='flex-end'>
                            {renderForwardBtn()}
                        </Grid>
                    </Grid>
                </Box>
            </Hidden>
        </Box>
    )

    function renderTeam(team: IGroup) {
        return (
            <ElementLink link={gameLink}>
                <Box height="100%" textAlign='center' justifyContent='center' display='flex' flexDirection='column' alignItems='center'>
                    <Img
                        className={classes.teamImage}
                        src={team.pic || DEFAULT_TEAM_LOGO}
                        failoverImage={DEFAULT_TEAM_LOGO}
                    />
                    <Paragraph1>
                        <b>
                            {generateTagLabel(team, null, categories, {
                                useTranslationsInsteadOfIntl: true,
                                translations: messages
                            })}
                        </b>
                    </Paragraph1>
                </Box>
            </ElementLink>
        )
    }

    function renderForwardBtn() {
        return  (
            <IconButton disabled={currentIndex === (games.length - 1)} style={{ borderColor: textColor }} onClick={() => onNavigate('next')}><ArrowForwardIcon htmlColor={textColor} /></IconButton>
        )
    }

    function renderBackBtn() {
        return (
            <IconButton disabled={currentIndex === 0} style={{ borderColor: textColor }} onClick={() => onNavigate('prev')}><ArrowBackIcon htmlColor={textColor} /></IconButton>
        )
    }

    function renderViewEventBtn() {
        return (
            <Box display='flex' flexDirection='column' mt={2}>
              {joinElements([
                <ButtonLink isPrimary={false} openInNewWindow href={FLF_SHOP_LINK} key={1} size='small'>
                    {intl.formatMessage(sectionMessages['navigation.btn.shop'])}
                </ButtonLink>,
                <ButtonLink isPrimary={false} openInNewWindow href={FLF_TICKETS_LINK} key={2} size='small'>
                    {intl.formatMessage(sectionMessages['navigation.btn.tickets'])}
                </ButtonLink>
                ], <Box mb={3}/>
              )}
            </Box>
        )
    }

    function getGameTime() {
        const momentDate = moment(currentGame?.start_date)
        return `${momentDate.format('HH')}h${momentDate.format('mm')}`
    }

    function renderGameDetails() {
        return (
            <>
                <Box py={4}>
                    <Slug text={<Paragraph2 isAccent>
                            <b>{currentGame?.competition?.name ?? intl.formatMessage(sectionMessages['games.friendly_game'])}</b>
                        </Paragraph2>}
                    />
                </Box>
                <div>
                  <Headline2>{simpleDateFormatter(currentGame?.start_date, 'DD.MM.YYYY')}</Headline2>
                  <Paragraph1><b>{getVenueDescription(currentGame, countries, messages)}</b></Paragraph1>
                </div>
            </>
        )
    }
}